'use strict';

require('../styles/main.less');

import React from 'react'; 
import ReactDom from 'react-dom'; 
import App from './components/app.js';

ReactDom.render(
	<App />,
	document.getElementById('content')
);
